












import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import API from '@/api/base'
import WidgetTitle from '@/components/common/WidgetTitle.vue'
import Loader from '@/components/common/Loader.vue'

@Component({
  components: {
    WidgetTitle,
    Loader
  }
})
export default class TextPage extends Vue {
  isLoading = true
  title = ''
  text = ''

  @Prop({ default: 0 }) WpPageID!: number

  @Watch('$route')
  loadContent(): void {
    this.getContent()
  }

  mounted(): void {
    this.getContent()
  }

  getContent(): void {
    this.isLoading = true
    API.getTextPageContent(this.WpPageID).then(response => {
      this.title = response?.data?.title.rendered
      this.text = response?.data?.content.rendered
    }).finally(() => {
      this.isLoading = false
    })
  }
}
